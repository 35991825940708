import React, {FunctionComponent,} from 'react';
import style from "./AuthErrorScreen.module.scss";
import {
    Alert,
    Button,
    Divider,
    IconButton,
    Typography
} from "@sber-friend/flamingo-core";

import {CloseIcon, ExternalLinkIcon,} from "@sber-friend/flamingo-icons";
import {getBrowserInfo, getUserAgent} from "../../helpers";
import {useNavigate} from "react-router-dom";
import {routesPath} from "../../routes/const";

interface OwnProps {
    closeError: () => void,
    code: number
}

type Props = OwnProps;

const AuthErrorScreen: FunctionComponent<Props> = ({closeError, code}) => {
    const DRUG_LINK = "https://sberfriend.sbrf.ru/sberfriend/#/application/9D1C838ED5796DD8E054022128574717";
    const browser = getBrowserInfo();
    const userAgent = getUserAgent();

    const navigate = useNavigate();


    const errorParser = (code: number) => {
        switch (code) {
            case 55 : {
                return <div className={style.AdBlockedError}>
                    <div className={style.header}>
                        <Typography variant={"h5"}>Учетная запись Sigma заблокирована</Typography>
                        <IconButton
                            onClick={closeError}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <Typography variant={"body1"}>Чтобы её разблокировать, можно:</Typography>

                    <Typography variant={"body1"} className={style.phoneField} style={{margin: "20px 0 4px"}}>
                        Позвонить&nbsp;
                        <a href="tel:88007070070">8-800-707-00-70</a>
                    </Typography>

                    <Typography variant={"body2"} color={"textSecondary"}>С мобильного, который у вас
                        в <a rel="noreferrer" href={"https://addressbook.sigma.sbrf.ru/search/profile"}
                             target={"_blank"} className={style.greenLink}>Справочнике <ExternalLinkIcon
                            fontSize={"small"}/></a>
                    </Typography>
                    <Divider style={{margin: "16px 0"}}/>
                    <Typography variant={"body1"} className={style.phoneField}>Позвонить в техподдержку на &nbsp;
                        <a href="tel:88005559340">8 800 555-93-40</a>
                    </Typography>
                    <Divider style={{margin: "16px 0"}}/>
                    <Typography variant={"body1"} style={{cursor: "pointer"}} onClick={() => {
                        window.open(DRUG_LINK, "_blank")
                    }}>Создать заявку в Друге</Typography>
                    <Typography variant={"body2"} color={"textSecondary"} style={{marginTop: 4}}>В сервисе&nbsp;
                        <a rel="noreferrer" href={DRUG_LINK} target={"_blank"} className={style.greenLink}>«Мой
                            компьютер/ноутбук»</a>
                        &nbsp;  выберите<br/> Устройство → Проблемы с входом → Разблокировать учётную запись
                    </Typography>
                </div>
            }
            case 49 :
            case 54 : {
                return <div className={style.AdBlockedError}>
                    <div className={style.header}>
                        <Typography variant={"h5"}>Ошибка входа</Typography>
                    </div>
                    <Typography variant={"body1"} color={"textSecondary"}>Свяжитесь с поддержкой:</Typography>

                    <Typography variant={"body1"} color={"textSecondary"} style={{marginTop: 16}}>Сервис&nbsp;
                        <a rel="noreferrer"
                           href={"https://sberfriend.sbrf.ru/sberfriend/#/application/97C2E1A8DE5B93ACE054022128574717"}
                           target={"_blank"} className={style.greenLink}>«Планшеты, смартфоны и ТСД»</a>
                        &nbsp;  в Друге
                    </Typography>

                    <Typography variant={"body1"} color={"textSecondary"} className={style.phoneField}
                                style={{marginTop: 8}}>
                        <a href="tel:855711000">8-557-11000</a>
                        &nbsp;– внутренний номер
                    </Typography>

                    <Typography variant={"body1"} color={"textSecondary"} className={style.phoneField}
                                style={{marginTop: 8}}>
                        <a href="tel:+74957000000">+7 495 700-00-00</a>
                        &nbsp;– внешний номер
                    </Typography>
                    <Typography variant={"caption2"} color={"textSecondary"} style={{marginTop: 16, display: "block"}}>Код
                        ошибки: {code}</Typography>
                    <Button color={"primary"} style={{marginTop: 16, width: 120}} onClick={closeError}>Закрыть</Button>
                </div>
            }
            case 53:
            case 56:
            case 57:
            case 58:
            case 63: {
                return <div className={style.AdBlockedError}>
                    <div className={style.header}>
                        <Typography variant={"h5"}>Сертификат устройства не подходит</Typography>
                    </div>
                    {
                        (browser !== "Safari" && userAgent === "ios") &&
                        <Alert severity={"warning"} style={{marginBottom: 30, marginTop: 10}}>Используйте браузер <a
                            onClick={(e) => e.preventDefault()}
                            href="##" style={{textDecoration: "none"}}>Safari</a> для работы с порталом</Alert>
                    }
                    <Typography variant={"body1"} color={"textSecondary"} style={{marginBottom: 32}}>
                        Проверьте установку <span className={style.greenLink} style={{display: "inline"}}
                                                  onClick={() => navigate(routesPath.instructions)}>
                        сертификатов МинЦифры и Аутентификации</span>
                    </Typography>
                    <Typography variant={"body1"} color={"textSecondary"}>
                        Если сертификаты установлены — свяжитесь с поддержкой:
                    </Typography>

                    <Typography variant={"body1"} color={"textSecondary"} style={{marginTop: 16}}>Сервис&nbsp;
                        <a rel="noreferrer"
                           href={"https://sberfriend.sbrf.ru/sberfriend/#/application/97C2E1A8DE5B93ACE054022128574717"}
                           target={"_blank"} className={style.greenLink}>«Планшеты, смартфоны и ТСД»</a>
                        &nbsp;  в Друге
                    </Typography>

                    <Typography variant={"body1"} color={"textSecondary"} className={style.phoneField}
                                style={{marginTop: 8}}>
                        <a href="tel:855711000">8-557-11000</a>
                        &nbsp;– внутренний номер
                    </Typography>

                    <Typography variant={"body1"} color={"textSecondary"} className={style.phoneField}
                                style={{marginTop: 8}}>
                        <a href="tel:+74957000000">+7 495 700-00-00</a>
                        &nbsp;– внешний номер
                    </Typography>
                    <Typography variant={"caption2"} color={"textSecondary"} style={{marginTop: 16, display: "block"}}>Код
                        ошибки: {code}</Typography>
                    <Button color={"primary"} style={{marginTop: 16, width: 120}} onClick={closeError}>Закрыть</Button>
                </div>
            }
        }
    }


    return (
        <div className={style.wrap}>
            {errorParser(code)}
        </div>
    );
};

export default AuthErrorScreen;
