import {FunctionComponent} from 'react';
import {useNavigate} from "react-router-dom";
import {routesPath} from "../../routes/const";
import {useEffectOnce} from "usehooks-ts";
import {getUserAgent,} from "../../helpers";
import {sendEvent} from '@sbol/clickstream-agent/sdk';


interface OwnProps {
}

type Props = OwnProps;

const QrCodeDownload: FunctionComponent<Props> = (props) => {
    const navigate = useNavigate();
    const userAgent = getUserAgent();


    const onMount = async () => {
        if (userAgent === "ios") {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Скачать приложение',
                    eventAction: 'QR-code',
                    value: 'iosDownload'
                }
            )
            //
            window.location.href = routesPath.instructions;
        }
        if (userAgent === "android") {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Скачать приложение',
                    eventAction: 'QR-code',
                    value: 'androidDownload'
                }
            )
            //
            window.location.href = "/store/android";
        }
        navigate(routesPath.main)
    }

    useEffectOnce(() => {
        onMount()
    })

    return null


};

export default QrCodeDownload;
