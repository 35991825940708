import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button, CircularLoader, IconButton,
    TextField,
    Typography, Tooltip,
} from "@sber-friend/flamingo-core";
import style from "./MyDevices.module.scss";
import SidePanel from "../../../components/SidePanel/SidePanel";
import AppTable from "../../../components/AppTable/AppTable";

import {
    useAddDeviceMutation,
    useDeleteAllDeviceMutation,
    useDeleteDeviceMutation,
    useGetDevicesQuery,
    useIsDeviceAcceptedMutation,
    INewDevice,
    useSendPortalMessageMutation,
    useGetUserRoleQuery,
    useLazyGetCyberPassUrlQuery
} from "../../../api/devices";
import {useEffectOnce, useWindowSize} from "usehooks-ts";
import {formatDateTime, getCookie, getUserAgent} from "../../../helpers";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import moment from "moment";
import QRCode from "react-qr-code";
import {useGetSegmentMutation} from "../../../api/main";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";
import {
    AndroidIcon,
    CloseIcon, CopyIcon,
    ExternalLinkIcon,
    HelpCircleIcon,
    IosIcon,
    PlusIcon, RepeatIcon,
    TrashIcon
} from '@sber-friend/flamingo-icons';
import CyberPassStep1 from "../../../assets/img/Devices/cyberpass_step1.png";
import CyberPassStep3 from "../../../assets/img/Devices/cyberpass_step3.png";
import CyberPassClipboard from "../../../assets/img/Devices/ClipboardIcon.svg";
import CyberPassIcon from "../../../assets/img/Devices/CyberPass.png";
import CyberPassPlusIcon from "../../../assets/img/Devices/CyberPassPlus.svg";
import ReloadIcon from "../../../assets/img/Devices/ReloadIcon.svg";
import {useNavigate, useSearchParams} from "react-router-dom";
import {OpenInstallInstr, routesPath} from "../../../routes/const";

require('moment-precise-range-plugin');

declare module "moment" {
    interface PreciseRangeValueObject {
        years: number;
        months: number;
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        firstDateWasLater: boolean;
    }

    interface Moment {
        preciseDiff(d2: Moment, returnValueObject?: false): string;

        preciseDiff(d2: Moment, returnValueObject: true): PreciseRangeValueObject;
    }

    function preciseDiff(d1: Moment, d2: Moment, returnValueObject?: false): string;
    function preciseDiff(d1: Moment, d2: Moment, returnValueObject: true): PreciseRangeValueObject;
}

export interface IDeleteDevice {
    name: string,
    status: boolean,
    platform: string,
    device_id: string
}

interface OwnProps {
}

type Props = OwnProps;

const MyDevices: FunctionComponent<Props> = () => {


    const SBERAPPSLINK = "https://sberapps.sber.ru/";
    const INSTRUCTION_PDF_LINK = "https://otp.sberbank.ru/instructions/new_instruction.pdf";
    const ANDROID_INSTRUCTION_PDF_LINK = "https://otp.sberbank.ru/instructions/Android_instruction.pdf";
    const CHECK_DEVICE_REQ_TIMEOUT = 1000;
    const TRY_COUNT_LIMIT = 30;
    let tryCount = 0;

    const userAgent = getUserAgent();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const {width} = useWindowSize();

    // --- запросы
    const [, {data: segmentData}] = useGetSegmentMutation({fixedCacheKey: 'shared-segment',})

    const {data: userRole} = useGetUserRoleQuery(undefined);
    const [getCyberPassUrl, {
        data: getCyberPassUrlData,
        error: cyberPassUrlError,
        isFetching: getCyberPassUrlLoading,
    }] = useLazyGetCyberPassUrlQuery();

    const {
        data: getDevicesData,
        isFetching: getDevicesLoading,
        error: getDevicesError,
        refetch: refetchDevicesData
    } = useGetDevicesQuery(undefined)

    const [fetchDeleteDevice, deleteDeviceData] = useDeleteDeviceMutation()
    const [fetchDeleteAllDevices, deleteAllDevicesData] = useDeleteAllDeviceMutation()
    const [fetchAddDevice, fetchAddDeviceData] = useAddDeviceMutation()
    const [isDeviceAccepted] = useIsDeviceAcceptedMutation()

    const [sendPortalMessage] = useSendPortalMessageMutation()


    // ---
    const [deleteError, setDeleteError] = useState('');
    const [addError, setAddError] = useState('');

    const [confirmModal, setConfirmModal] = useState(false);
    const [deleteDevice, setDeleteDevice] = useState<undefined | Array<IDeleteDevice>>();
    const [addDeviceCode, setAddDeviceCode] = useState("");
    const [addDeviceLoad, setAddDeviceLoad] = useState(false);
    const [successfulDeviceAddition, setSuccessfulDeviceAddition] = useState(false);

    const [cyberPassInstr, setCyberPassInstr] = useState(false);
    const [cyberPassMobInstrStep, setCyberPassMobInstrStep] = useState(1);
    const [cyberPassUrlCopied, setCyberPassUrlCopied] = useState(false);
    const [cyberPassUrlCopiedLoad, setCyberPassUrlCopiedLoad] = useState(false);
    const [cyberPassUrlCopiedError, setCyberPassUrlCopiedError] = useState(false);


    const [openSidePanel, setOpenSidePanel] = useState(false);


    const codeField = useRef<HTMLInputElement>(null);

    const handleOpenSidePanel = () => {
        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Устройства',
                eventAction: 'клик на кнопку Зарегистрировать',
                value: ''
            }
        )
        //
        setOpenSidePanel(true)
        if (userAgent === "ios" && userRole?.result.cyberpass_visible) {
            getCyberPassUrl(undefined)
        }
    }

    const handleCloseSidePanel = () => {
        setOpenSidePanel(false)
        if (cyberPassMobInstrStep > 2) {
            setCyberPassMobInstrStep(1)
        }
    }

    const clearSidePanel = () => {
        setAddDeviceCode("")
    }

    async function checkDeviceAccepted(device: INewDevice) {

        const deviceAccepted = await isDeviceAccepted(device);
        if ("data" in deviceAccepted && deviceAccepted.data?.result) {

            // click stream
            try {
                let logInTime = getCookie('logInTime')
                if (!!logInTime) {

                    let durationSuccessRegistrDevice = Number(Date.now() - Number(logInTime));

                    const payload = {
                        message: "Устройства, затраченное время на регистрацию",
                        value: durationSuccessRegistrDevice,
                    }
                    // отправка метрики на наш бэк
                    sendPortalMessage(payload)
                    //
                    sendEvent(
                        {
                            eventCategory: 'Устройства',
                            eventAction: `затраченное время предъявления успешного регистрационного кода, сегмент ${segmentData?.result?.sigma ? "Sigma" : "Internet"}/${userAgent}`,
                            value: durationSuccessRegistrDevice.toString(),
                        }
                    )
                }


            } catch (e) {
                console.log('logInTimeError', e)
            }
            //

            setSuccessfulDeviceAddition(true)
            setAddDeviceLoad(false)
            setOpenSidePanel(false)
            clearSidePanel()
        } else {
            if (tryCount < TRY_COUNT_LIMIT) {
                console.debug("checkDeviceAccepted -> tryCount", tryCount);
                tryCount += 1;
                setTimeout(() => {
                    checkDeviceAccepted(device);
                }, CHECK_DEVICE_REQ_TIMEOUT);
            } else {
                setAddError('Время ожидания добавления устройства истекло, попробуйте еще раз')
                setAddDeviceLoad(false)
                clearSidePanel()
                setOpenSidePanel(false)
                tryCount = 0;
            }
        }

    }

    const handleAddDevice = async () => {

        setAddDeviceLoad(true)
        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Устройства',
                eventAction: 'боковая панель/клик на кнопку Добавить устройство',
                value: ''
            }
        )
        //
        const res = await fetchAddDevice(addDeviceCode)
        if ("data" in res && res.data?.status === "ok") {

            let newDevice = {
                device_id: res.data.result.device_id,
                platform: res.data.result.platform,
            }

            checkDeviceAccepted(newDevice)

            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Устройства',
                    eventAction: 'боковая панель/успешное предъявление регистрационного кода',
                    value: ''
                }
            )
            //
            // if (userAgent === "ios") {
            //     const a = document.createElement('a');
            //     a.href = "cyberpass://otp"
            //     a.click();
            // }
            if (userAgent === "android") {
                const a = document.createElement('a');
                a.target = "_blank"
                a.href = "sberstore://otp"
                a.click();
            }
        } else {
            setAddDeviceLoad(false)
        }


    }

    const handleClickOnDeleteAll = () => {
        setDeleteDevice(undefined)
        setConfirmModal(true)
    }
    const handleClickOnDeleteOne = (e: IDeleteDevice) => () => {
        setDeleteDevice([e])
        setConfirmModal(true)
    }

    const handleDeleteDevices = async () => {
        if (deleteDevice) {
            await fetchDeleteDevice(deleteDevice[0])
        } else {
            await fetchDeleteAllDevices(undefined)
        }
    }

    const handleClickInstruction = () => {
        window.open(ANDROID_INSTRUCTION_PDF_LINK, "_blank")
    }

    const openCyberPassInstr = () => {
        setCyberPassInstr(true)
        getCyberPassUrl(undefined)
    }

    const fallbackCopyTextToClipboard = (text: string) => {
        const textArea = document.createElement('textarea');
        textArea.style.position = 'fixed';
        textArea.style.opacity = "0";
        textArea.value = text;
        const wrap = document.querySelector("." + style.cyberPassInstrMobWrap)
        wrap?.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            if (successful) {
                console.log({successful})
                setCyberPassUrlCopied(true);
                setCyberPassMobInstrStep(3);
            } else {
                setCyberPassUrlCopied(false);
            }
        } catch (err) {
            setCyberPassUrlCopied(false);
            console.log(err)
        }
        wrap?.removeChild(textArea);
    };

    const copyTextToClipboard = async () => {
        try {
            setCyberPassUrlCopiedLoad(true);
            if (getCyberPassUrlData?.result?.url) {
                setCyberPassUrlCopied(false);
                try {
                    await navigator.clipboard.writeText(getCyberPassUrlData.result?.url);
                    setCyberPassUrlCopied(true);
                    setCyberPassMobInstrStep(3);
                } catch (clipboardError) {
                    console.warn('Clipboard API не работает, используем fallback', clipboardError);
                    fallbackCopyTextToClipboard(getCyberPassUrlData.result?.url);
                }
            }
            setCyberPassUrlCopiedLoad(false);
        } catch (err) {
            setCyberPassUrlCopiedLoad(false);
            setCyberPassUrlCopiedError(true);
            console.error('Ошибка', err);
        }
    };


    const onCloseModal = () => {
        if (!deleteDeviceData.isLoading && !deleteAllDevicesData.isLoading) {
            setConfirmModal(false)
        }
    }

    const getExpiredDays = (days: number, status: boolean) => {
        if (status) {
            return <span className={style.statusWrap}>
                <span>Ждёт подтверждения</span>
                <Tooltip
                    title={<Typography variant={"body2"}>Подтвердите личность <br/>на регистрируемом
                        устройстве</Typography>}
                    placement={"top"}
                    arrow
                >
                    <HelpCircleIcon fontSize={"small"} style={{cursor: "pointer", marginLeft: 8}}
                                    className={style.textSecondary}/>
                </Tooltip>
            </span>
        }
        if (180 > days && days > 0) {
            return <span className={style.statusWrap}>
                <span
                    className={style.status + " " + style.error}>Активно до {moment().add(days, 'days').format("DD.MM.YYYY")}
                </span>
                <Tooltip
                    title={<Typography variant={"body2"}>Если вы не заходите в приложение больше 180 дней, устройство
                        нужно регистрировать снова. Иначе зайти в приложение не получится</Typography>}
                    placement={"top"}
                    arrow
                >
                    <HelpCircleIcon fontSize={"small"} style={{cursor: "pointer"}} className={style.textSecondary}/>
                </Tooltip>
            </span>
        }

        if (days > 0) {
            return <span className={style.statusWrap}>
                <span
                    className={style.status}>Активно до {moment().add(days, 'days').format("DD.MM.YYYY")}
                </span>
                <Tooltip
                    title={<Typography variant={"body2"}>Если вы не заходите в приложение больше 180 дней, устройство
                        нужно регистрировать снова. Иначе зайти в приложение не получится</Typography>}
                    placement={"top"}
                    arrow
                >
                    <HelpCircleIcon fontSize={"small"} style={{cursor: "pointer"}} className={style.textSecondary}/>
                </Tooltip>
            </span>
        }

        return <span className={style.statusWrap}>
            <span className={style.status + " " + style.error}>Неактивно</span>
            <Tooltip
                title={<Typography variant={"body2"}>Вы не заходили в приложение ОТР больше 180 дней — устройство нужно
                    зарегистрировать снова</Typography>}
                placement={"top"}
                arrow
            >
                <HelpCircleIcon fontSize={"small"} style={{cursor: "pointer"}} className={style.textSecondary}/>
            </Tooltip>
        </span>
    }

    const column = {
        nameWithIcon: "Устройство",
        last_active_time: "Последний вход",
        statusStr: "Статус",
        actions: "",
    }

    const list = getDevicesData ? [...getDevicesData.result.pendingDevices.map(e => {
        let newEl = {
            ...e,
            nameWithIcon: <span className={style.nameWrap}>
                <span className={style.iconName}>{e.platform === "ios" ? <IosIcon/> : <AndroidIcon/>}</span>
                {e.name}
            </span>,
            last_active_time: <>{formatDateTime(e.last_active_time)}
                {e.ip_address && <>
                    <br/>ip: {e.ip_address}
                </>} </>,
            statusStr: getExpiredDays(Number(e.expired_days), e.status),
            actions: <Button style={{float: "right"}} className={style.textSecondary} variant={"text"}
                             startIcon={<TrashIcon/>}
                             onClick={handleClickOnDeleteOne(e)}>
                <Typography variant={"body2"}>Удалить</Typography>
            </Button>
        }
        return newEl
    }), ...getDevicesData.result.sessions.map(e => {
        let newEl = {
            ...e,
            nameWithIcon: <span className={style.nameWrap}>
                <span className={style.iconName}>{e.platform === "ios" ? <IosIcon/> : <AndroidIcon/>}</span>
                {e.name}
            </span>,
            last_active_time: <>{formatDateTime(e.last_active_time)}
                {e.ip_address && <>
                    <br/>ip: {e.ip_address}
                </>} </>,
            statusStr: getExpiredDays(Number(e.expired_days), e.status),
            actions: <Button style={{float: "right"}} className={style.textSecondary} variant={"text"}
                             startIcon={<TrashIcon/>}
                             onClick={handleClickOnDeleteOne(e)}>
                <Typography variant={"body2"}>Удалить</Typography>
            </Button>
        }
        return newEl
    })] : []

    useEffect(() => {
        if (fetchAddDeviceData?.error) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Устройства',
                    eventAction: 'боковая панель/ошибка при Добавлении устройства',
                    value: `${fetchAddDeviceData?.error?.code}: ${fetchAddDeviceData?.error?.message}`,
                }
            )
            //
        }
    }, [fetchAddDeviceData?.error])

    useEffect(() => {
        if (deleteDeviceData?.error?.message) {
            setDeleteError(deleteDeviceData?.error?.message)
        }
        if (deleteAllDevicesData?.error?.message) {
            setDeleteError(deleteAllDevicesData?.error?.message)
        }
    }, [deleteDeviceData.error, deleteAllDevicesData.error])

    useEffect(() => {
        if (cyberPassUrlError) {
            setCyberPassUrlCopiedError(true)
        }
    }, [cyberPassUrlError])

    const onMount = () => {
        const params = Object.fromEntries(searchParams.entries())
        if (params?.[OpenInstallInstr] && userRole?.result.cyberpass_visible) {
            setOpenSidePanel(true)
            openCyberPassInstr()
            navigate(routesPath.devices)
        }
    }

    useEffectOnce(() => {
        onMount()
    })


    return (
        <>
            <div className={style.myDevicesBody}>
                <div className={style.tableWrap}>
                    {getDevicesLoading ? <div style={{height: 400}} className={"center"}>
                            <CircularLoader size={"large"}/>
                        </div>
                        : (getDevicesError?.message ? <div className={style.devicesErrorWrap}>
                                    <Alert severity={"error"}>{getDevicesError?.message}</Alert>
                                    <Button color="primary"
                                            style={{marginTop: 20}}
                                            onClick={refetchDevicesData}
                                    >
                                        Попробовать снова
                                    </Button>
                                </div>
                                : (list.length ? <>
                                        {(Number(getDevicesData?.result?.maxDevices) < list.length) &&
                                            <Alert style={{marginBottom: 16}} severity={"error"}>{
                                                `Вы достигли максимального количества устройств (${getDevicesData?.result?.maxDevices} шт.). Чтобы добавить новое устройство, удалите существующее из списка`
                                            }</Alert>
                                        }
                                        <div className={style.tableActions}>
                                            <Button variant={"text"}
                                                    startIcon={<TrashIcon/>}
                                                    className={style.deleteAllButton}
                                                    onClick={handleClickOnDeleteAll}
                                            >
                                                <Typography variant={"h6"}>Удалить все</Typography>
                                            </Button>
                                            <Button startIcon={<PlusIcon/>}
                                                    onClick={handleOpenSidePanel}
                                                    color={"primary"}
                                                    disabled={Number(getDevicesData?.result?.maxDevices) <= list.length}
                                            >
                                                Зарегистрировать
                                            </Button>
                                        </div>
                                        {
                                            width > 767 ?
                                                <AppTable column={column} rows={list}/>
                                                : <div className={style.mobileTable}>
                                                    {list.map((e, i) => <div className={style.mobileTableItem}
                                                                             key={i + e.device_id}>
                                                        <div className={style.head}>
                                                            {e.name}
                                                            <Button variant={"text"}
                                                                    className={style.textSecondary}
                                                                    onClick={handleClickOnDeleteOne(e)}
                                                                    startIcon={<TrashIcon/>}
                                                            >
                                                                <Typography variant={"body2"}>Удалить</Typography>
                                                            </Button>
                                                        </div>
                                                        <Typography variant={"body2"}>
                                                            <span className={style.textSecondary}>Последний вход: </span>
                                                            {e.last_active_time}
                                                        </Typography>
                                                    </div>)}
                                                </div>
                                        }
                                        <a href={ANDROID_INSTRUCTION_PDF_LINK} rel="noreferrer" target={"_blank"}
                                           className={style.instructionPdf}>Инструкция по
                                            регистрации <ExternalLinkIcon fontSize={"small"}/>
                                        </a>
                                    </> : <div className={style.emptyList}>
                                        <Typography variant={"body1"} color={"textSecondary"} className={style.text}>
                                            Для начала работы<br/> зарегистрируйте мобильное устройство
                                        </Typography>
                                        <Button startIcon={<PlusIcon/>}
                                                onClick={handleOpenSidePanel}
                                                color={"primary"}
                                        >
                                            Зарегистрировать
                                        </Button>
                                        <Typography onClick={handleClickInstruction} variant={"h6"}
                                                    style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                                            <ExternalLinkIcon fontSize={"small"}/>&nbsp;Открыть инструкцию
                                        </Typography>
                                    </div>
                                )
                        )
                    }
                </div>
                <div className={style.appsWrap}>
                    {userRole?.result.cyberpass_visible && userAgent !== "android" &&
                        <div className={style.cyberPassInstrAlert}>
                            <Alert severity={"warning"}>
                                Если у вас iOS — <a href="/" onClick={(e) => {
                                e.preventDefault()
                                setOpenSidePanel(true)
                                openCyberPassInstr()
                            }}
                                                    style={{textDecoration: "none"}}>скачайте приложение</a> CyberPass
                                из
                                Appstore <br/><br/>
                                <a href={INSTRUCTION_PDF_LINK}
                                   rel="noreferrer" target={"_blank"}
                                   style={{textDecoration: "none"}}
                                >Инструкция IOS PDF</a>
                            </Alert>
                        </div>}
                    <div className={style.qrCodeWrap}>
                        {/*<QRCode*/}
                        {/*    size={256}*/}
                        {/*    style={{height: "auto", maxWidth: "65px", width: "100%"}}*/}
                        {/*    value={SBERAPPSLINK}*/}
                        {/*    viewBox={`0 0 256 256`}*/}
                        {/*/>*/}
                        <Typography variant={"body1"} className={"content-pre-line"}>
                            Магазин{'\n'}
                            корпоративных{'\n'}
                            приложений <a href={SBERAPPSLINK} className="green-link">Apps <ExternalLinkIcon
                            fontSize={"small"}/></a>
                        </Typography>
                    </div>
                    <Typography variant={"body1"}>Здесь собраны все внутренние приложения для сотрудников
                        Экосистемы</Typography>
                </div>
            </div>
            <SidePanel open={openSidePanel}
                       onOpen={handleOpenSidePanel}
                       onClose={handleCloseSidePanel}
                       header={"Зарегистрировать устройство"}
            >
                {userAgent !== "ios" &&
                    <Typography variant={"body1"} style={{marginBottom: 32}}>Нам нужно убедиться, что вы — сотрудник
                        Банка,
                        и устройство
                        принадлежит вам</Typography>}
                {userRole?.result.cyberpass_visible && <>
                    {userAgent === "web" && <div className={style.cyberPassInstrWrap}>
                        {
                            cyberPassInstr ? <div className={style.cyberPassInstr}>
                                    <ol>
                                        <li><Typography variant={"h6"} style={{display: "inline"}}>Установите на
                                            устройство <a href={INSTRUCTION_PDF_LINK}
                                                          rel="noreferrer"
                                                          target={"_blank"}
                                                          className={"green-link"}>сертификаты
                                                Минцифры и Аутентификации</a></Typography>
                                        </li>
                                        <li><Typography variant={"h6"}>Скачайте приложение&nbsp; <img src={CyberPassIcon}
                                                                                                      style={{width: 32}}
                                                                                                      alt="CyberPassIcon"/>
                                            &nbsp;CyberPass в AppStore</Typography>
                                        </li>
                                        <li><Typography variant={"h6"}>В приложении CyberPass нажмите &nbsp;
                                            <img src={CyberPassPlusIcon} alt="CyberPassPlusIcon"/></Typography></li>
                                        <li><Typography variant={"h6"}>Отсканируйте QR-код</Typography></li>
                                    </ol>
                                    <div className={style.qrWrap}>
                                        {
                                            getCyberPassUrlLoading ? <CircularLoader size={"large"}/>
                                                : (getCyberPassUrlData?.result?.url ? <QRCode
                                                    size={256}
                                                    style={{height: "auto", maxWidth: "110px", width: "100%",}}
                                                    value={getCyberPassUrlData?.result?.url}
                                                    viewBox={`0 0 256 256`}
                                                /> : < Typography variant={"h6"} color={"error"}>Ошибка генерации
                                                    QR</Typography>)
                                        }
                                        <div className={style.qrText}>
                                            <Button variant={"text"} onClick={() => getCyberPassUrl(undefined)}>
                                                <Typography color={"primary"} variant={"h6"}>
                                                    <img src={ReloadIcon} alt="ReloadIcon"/>&nbsp;
                                                    Сгенерировать другой</Typography></Button>
                                            <Typography variant={"body1"}>После сканирования<br/> вы увидите выбор
                                                сегмента<br/> и регистрационный код
                                            </Typography>
                                        </div>
                                    </div>
                                    <Alert severity={"warning"}>
                                        Важно! Не оставляйте оценку приложению в AppStore, иначе его удалят по решению Apple
                                    </Alert>
                                </div>
                                : <Alert severity={"warning"} buttons={[{
                                    id: 'alert-button-1',
                                    label: 'Перейти к инструкции',
                                    onClick: openCyberPassInstr,
                                }]}>
                                    Если у вас iOS — скачайте приложение CyberPass из Appstore
                                </Alert>
                        }
                    </div>
                    }
                    {userAgent === "ios" && cyberPassMobInstrStep !== 4 &&
                        <div className={style.cyberPassInstrMobWrap}>
                            {(cyberPassMobInstrStep === 1) && <>
                                <Typography variant={"h5"}>1. &nbsp;Скачайте приложение</Typography>
                                <Typography variant={"h5"}>
                                    <img src={CyberPassIcon} style={{width: 32}} alt="CyberPassIcon"/>
                                    &nbsp;CyberPass в AppStore
                                </Typography>
                                <div className={style.imgWrap}>
                                    <img src={CyberPassStep1} alt="CyberPassStep1"/>
                                </div>
                                <Button fullWidth color={"primary"}
                                        onClick={() => setCyberPassMobInstrStep(2)}>Дальше</Button>
                            </>}
                            {(cyberPassMobInstrStep === 2) && <>
                                <Typography variant={"h5"}>2. &nbsp;Скопируйте ссылку
                                    по кнопке ниже</Typography>
                                <div className={style.imgWrap}>
                                    <Alert severity={"warning"}>
                                        Важно! Не оставляйте оценку приложению в AppStore, иначе его удалят
                                        по решению Apple
                                    </Alert>
                                </div>
                                {
                                    cyberPassUrlError ?
                                        <Button color="primary"
                                                startIcon={<RepeatIcon/>}
                                                fullWidth
                                                loading={getCyberPassUrlLoading}
                                                onClick={() => getCyberPassUrl(undefined)}
                                        >
                                            Сгенерировать снова
                                        </Button>
                                        : <Button color="primary"
                                                  startIcon={<CopyIcon/>}
                                                  fullWidth
                                                  disabled={!getCyberPassUrlData}
                                                  loading={cyberPassUrlCopiedLoad || getCyberPassUrlLoading}
                                                  onClick={copyTextToClipboard}
                                        >
                                            Копировать
                                        </Button>
                                }

                            </>}
                            {(cyberPassMobInstrStep === 3) && <>
                                <Typography variant={"h5"}>3. &nbsp;Перейдите в приложение</Typography>
                                <Typography variant={"h5"}>
                                    CyberPass и нажмите &nbsp;
                                    <img src={CyberPassClipboard} alt="CyberPassClipboard"/>
                                </Typography>
                                <div className={style.imgWrap}>
                                    <img src={CyberPassStep3} alt="CyberPassStep3"/>
                                </div>
                                <Button fullWidth color={"primary"}
                                        onClick={() => setCyberPassMobInstrStep(4)}>Дальше</Button>
                            </>}

                        </div>}
                </>}
                {(userAgent !== "ios" || cyberPassMobInstrStep === 4 || !userRole?.result.cyberpass_visible) &&
                    <div className={style.step2}>
                        <div style={{display: "flex"}}>
                            {/*<Typography variant={"h5"}>1. &nbsp;</Typography>*/}
                            <Typography variant={"h5"} style={{marginBottom: 16}} className={"content-pre-line"}>Подтвердите
                                устройство{'\n'}
                                кодом регистрации из приложения OTP</Typography>
                        </div>
                        <div className={style.actions}>
                            <TextField placeholder={"25YJ4K"}
                                       inputRef={codeField}
                                       disabled={addDeviceLoad}
                                       value={addDeviceCode}
                                       helperText={fetchAddDeviceData?.error?.message &&
                                           <span className={"errorText"}
                                                 dangerouslySetInnerHTML={{__html: fetchAddDeviceData?.error?.message}}/>}
                                       error={!!fetchAddDeviceData?.error?.message}
                                       endIcon={<IconButton onClick={() => setAddDeviceCode("")}
                                                            size={"small"}><CloseIcon/></IconButton>}
                                       onKeyDown={(e: any) => {
                                           if (e.key === "Enter" && addDeviceCode.length === 6) {
                                               handleAddDevice()
                                           }
                                       }}
                                       customCounter={`${addDeviceCode.length}/6`}
                                       onChange={(e: any) => {
                                           let newVal = e.target.value.replace(/[^a-zA-Z0-9]/g, '').replace(/[0]/g, '').toUpperCase()
                                           if (newVal.length < 7)
                                               setAddDeviceCode(newVal)
                                       }}
                            />
                            <Button color={"primary"}
                                    loading={addDeviceLoad}
                                    onClick={handleAddDevice}
                                    disabled={addDeviceCode.length !== 6}
                            >
                                Добавить
                            </Button>
                        </div>
                    </div>}
                {/*<div className={style.downloadWrap}>*/}
                {/*    <QrCodeDownload/>*/}
                {/*    <Typography variant={"body1"}>Если вы ещё не установили приложение OTP, отсканируйте QR-код или*/}
                {/*        откройте*/}
                {/*        otp.sberbank.ru с телефона</Typography>*/}
                {/*</div>*/}
            </SidePanel>
            <Snackbar
                placement={"top"}
                variant="error"
                open={!!deleteError}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    setDeleteError('')
                }}
                message={deleteError}
            />
            <Snackbar
                placement={"top"}
                variant="error"
                open={!!addError}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    setAddError('')
                }}
                message={addError}
            />
            <Snackbar
                placement={"top"}
                variant="error"
                open={cyberPassUrlCopiedError}
                autoHideDuration={5000}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    setCyberPassUrlCopiedError(false)
                }}
                message={"Ошибка генерации ссылки – попробуйте снова"}
            />
            <Snackbar
                placement={"top"}
                open={cyberPassUrlCopied}
                autoHideDuration={5000}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    setCyberPassUrlCopied(false)
                }}
                message={"Ссылка скопирована"}
            />
            <Snackbar
                placement={"top"}
                open={successfulDeviceAddition}
                autoHideDuration={5000}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    setSuccessfulDeviceAddition(false)
                }}
                message={"Устройство успешно добавлено"}
            />
            <ConfirmModal openModal={confirmModal}
                          onConfirm={handleDeleteDevices}
                          deleteDeviceName={deleteDevice?.map(e => e.name)}
                          onCloseModal={onCloseModal}
            />
        </>
    );
};

export default MyDevices;
