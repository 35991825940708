import {FunctionComponent} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {routesPath} from "../../routes/const";
import {setBaseUrl, setSudirUser} from "../../api/auth/authSlice";
import {useAppDispatch} from "../../hooks/redux";
import {useEffectOnce} from "usehooks-ts";
import {useGetSegmentMutation} from "../../api/main";
import {getTokenFromSessionStorage} from "../../api/jwt";
import {setCookie} from "../../helpers";
import {sendEvent} from '@sbol/clickstream-agent/sdk';


interface OwnProps {
}

type Props = OwnProps;

const SudirAuthPage: FunctionComponent<Props> = (props) => {

    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [getSegment,] = useGetSegmentMutation({fixedCacheKey: 'shared-segment',})


    const onMount = async () => {


        const getSegmentRes = await getSegment(undefined)
        if ("data" in getSegmentRes && getSegmentRes.data.status === "ok") {
            if (getSegmentRes.data.result?.sigma) {
                setCookie('logInTime', Date.now().toString())
                setCookie('sudirUser', true, {});
                dispatch(setSudirUser(true))
                dispatch(setBaseUrl('/api/portal/sudir'))
                // отправка метрик click stream
                sendEvent(
                    {
                        eventCategory: 'Вход в Устройства',
                        eventAction: 'Сертификат/успешный вход в Устройства',
                        value: ''
                    }
                )
                //
            } else {
                const t = await getTokenFromSessionStorage();
                if (t) {
                    setCookie('sudirUser', true, {});
                    dispatch(setSudirUser(true))
                    dispatch(setBaseUrl('/api/portal/sudir'))
                }
            }

        }

        navigate({
            pathname: routesPath.devices,
            search: searchParams.toString()
        })
    }

    useEffectOnce(() => {
        onMount()
    })

    return null


};

export default SudirAuthPage;
