import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {authApi, defineApiUrl} from "./authApi";
import {setSessionStorage} from "../jwt";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {routesPath} from "../../routes/const";


type AuthState = {
    sudirUser: boolean
    token: string
    baseUrl: string
}

const authSlice = createSlice({
    name: 'auth',
    initialState: {sudirUser: false, token: "", baseUrl: ""} as AuthState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        setSudirUser: (state, action: PayloadAction<boolean>) => {
            state.sudirUser = action.payload
        },
        setBaseUrl: (state, action: PayloadAction<string>) => {
            state.baseUrl = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, {payload}) => {
                const token = payload.result?.jwt
                const sudirUser = !!JSON.parse(window.atob(token.split('.')[1]))?.pilot

                // отправка метрик click stream
                try {
                    sendEvent(
                        {
                            eventCategory: 'Вход в Устройства',
                            eventAction: 'Логин и пароль/успешный вход в Устройства',
                            value: ''
                        }
                    )
                } catch (e) {
                    console.log(e)
                }
                const searchParams = new URLSearchParams(window.location.search);
                const sanitizedParams = new URLSearchParams();
                searchParams.forEach((value,key)=>{
                    sanitizedParams.append(key,encodeURIComponent(value))
                })
                state.token = token
                state.baseUrl = (process.env.NODE_ENV === 'development' ? '' : defineApiUrl()) + '/api/portal';
                setSessionStorage(token).finally(() => {
                    if (sudirUser) {
                        window.location.href = routesPath.sudirAuth + (sanitizedParams?.toString() ? "?" + sanitizedParams?.toString() : "");
                    }
                })


            }
        )
    },
})

export const {setToken, setSudirUser, setBaseUrl} = authSlice.actions


export default authSlice.reducer
