import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {DevicesPage} from "./DevicesPage";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import Login from "./Login/Login";
import {useEffectOnce, useEventListener} from "usehooks-ts";
import {clearSessionStorage, getTokenFromSessionStorage} from "../../api/jwt";
import {setBaseUrl, setSudirUser, setToken} from "../../api/auth/authSlice";
import {getCookie, setCookie} from "../../helpers";
import NoCert from "./NoCert/NoCert";
import {logOutSudir, useLazyGetUserRoleQuery} from 'api/devices';
import {useGetSegmentMutation} from "../../api/main";
import {routesPath} from "../../routes/const";
import {useNavigate, useSearchParams} from "react-router-dom";
import {defineApiUrl} from "../../api/auth/authApi";
import {CircularLoader} from "@sber-friend/flamingo-core";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";
import {sendEvent} from '@sbol/clickstream-agent/sdk';


interface OwnProps {
}

type Props = OwnProps;

const Devices: FunctionComponent<Props> = (props) => {

    const sudirUser = useAppSelector(state => state.auth.sudirUser);
    const token = useAppSelector(state => state.auth.token);
    const validToken = (token && !JSON.parse(window.atob(token.split('.')[1]))?.pilot)

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch()
    const noCert = false;

    const nullVar = null as unknown as number;

    const [getUserRole] = useLazyGetUserRoleQuery()
    const [getSegment, {data: getSegmentData}] = useGetSegmentMutation({fixedCacheKey: 'shared-segment',})

    const [loadingPage, setLoadingPage] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);

    const lastActiveTimestamp = useRef<number>(0)
    const userIsActiveForLogOut = useRef<boolean>(true)
    const checkUserInterval = useRef<null | ReturnType<typeof setInterval>>(null)
    const timeoutForLogoutSudir = 900000;

    const active = () => {
        lastActiveTimestamp.current = new Date().getTime();
        if (!userIsActiveForLogOut.current) {
            userIsActiveForLogOut.current = true;
            if (openAlert) {
                setTimeout(() => {
                    setOpenAlert(false)
                }, 7000)
            }
            console.log('user is active');
        }
    }

    useEventListener('mousemove', active)
    useEventListener('keypress', active)
    useEventListener('click', active)

    useEffect(() => {
        checkUserInterval.current = setInterval(async () => {
            if (userIsActiveForLogOut.current && (new Date().getTime() - lastActiveTimestamp.current > timeoutForLogoutSudir)) {
                console.log('user is not active, logout sudir');
                if (sudirUser) {
                    await logOutSudir()
                    setCookie('sudirUser', '')
                }
                setCookie('logInTime', "")
                setOpenAlert(true)
                dispatch(setToken(""))
                clearSessionStorage()
                dispatch(setSudirUser(false))

                userIsActiveForLogOut.current = false;

                if (getSegmentData?.result.sigma) {
                    navigate(routesPath.main)
                }
            }
        }, 1000)
        return () => {
            clearInterval(checkUserInterval.current ?? undefined);
        }
    }, [getSegmentData, navigate, dispatch, sudirUser])


    const onMount = async () => {
        if (window) {
            active();
        }
        const t = await getTokenFromSessionStorage();
        const sudirAuth = getCookie('sudirUser')
        if (t) {
            dispatch(setBaseUrl((process.env.NODE_ENV === 'development' ? '' : defineApiUrl()) + '/api/portal'))
            dispatch(setToken(t))
        }
        if (sudirAuth) {
            dispatch(setSudirUser(true))
            dispatch(setBaseUrl('/api/portal/sudir'))
            setCookie('logInTime',Date.now().toString())
        }
        if (sudirAuth || t){
            await getUserRole(undefined)
        } else {
            if (getSegmentData) {
                if (getSegmentData?.result.sigma){
                    // отправка метрик click stream
                    sendEvent(
                        {
                            eventCategory: 'Вход в Устройства',
                            eventAction: 'Сертификат/вход в Устройства',
                            value: ''
                        }
                    )
                    //
                    window.location.href = routesPath.sudirAuth + "?" + searchParams.toString();

                }
            } else {
                const getSegmentRes = await getSegment(undefined)
                if ("data" in getSegmentRes && getSegmentRes.data.status === "ok" && getSegmentRes.data.result?.sigma) {
                    // отправка метрик click stream
                    sendEvent(
                        {
                            eventCategory: 'Вход в Устройства',
                            eventAction: 'Сертификат/вход в Устройства',
                            value: ''
                        }
                    )
                    //
                    window.location.href = routesPath.sudirAuth+ "?" + searchParams.toString();
                }
            }
        }

    }

    useEffectOnce(() => {
        onMount().then(() => setLoadingPage(false))
    })

    if (loadingPage) {
        return <div style={{flex: "1 1 auto"}} className={"center"}>
            <CircularLoader size={"large"}/>
        </div>
    }

    if (noCert) {
        return <NoCert/>
    }

    if (validToken || sudirUser) {
        return <DevicesPage/>
    }
    return <>
        {!getSegmentData?.result.sigma ? <Login/> : null}
        <Snackbar
            placement={"top"}
            hideIcon
            open={openAlert}
            autoHideDuration={nullVar}
            iconButtonClose
            transitionDuration={300}
            TransitionProps={{}}
            onClose={() => {
                setOpenAlert(false)
            }}
            message={"Срок авторизации истек, пройдите авторизацию снова"}
        />
    </>

};

export default Devices;
