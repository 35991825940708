import React from 'react';
import RoutesApp from "./routes/RoutesApp";
import LayoutApp from "./components/LayoutApp/LayoutApp";
import {setConfig, setMeta} from '@sbol/clickstream-agent/sdk';
import {getUrlForClickStream} from 'helpers';


function App() {

    // Установка параметров работы агента clickstream
    setConfig({
        reportUrl: process.env.NODE_ENV !== 'development' ? getUrlForClickStream() : "/clickstream",
        deviceIdUrl: 'https://visor.sberbank.ru/get',
        bufferSize: 1
    })
    // Установка параметров приложения и устройства clickstream
    setMeta({
        apiKey: '9ffcec52b6c0af27c9c7e47423864cd6fc67b99ad353cb03482a5c6ac6323ed1'
    })

    return <LayoutApp>
        <RoutesApp/>
    </LayoutApp>
}

export default App;
